/* tinyMCE format examples
   Styles in will be automatically added to the top level of the formats menu.
   Condition is that the file is named tinymce-formats.css and registered in the TinyMCE controlpanel.
*/

.highlight-inline {
  background: #FFFE14;
}

p.highlight-paragraph {
  background: #FFFE14;
  padding: 1rem;
}
