:root,
[data-bs-theme="light"] {
  // Note: Custom variable values only support SassScript inside `#{}`.

  // Colors
  //
  // Generate palettes for full colors, grays, and theme colors.

  @each $color,
  $value in $plone-colors {
    --plone-#{$color}: #{$value};
  }

  @each $color,
  $value in $state-colors {
    --plone-state-#{$color}: #{$value};
  }
}

@if $enable-dark-mode {
    @include color-mode(dark, true) {
      // scss-docs-start root-dark-mode-vars
      @each $color,
      $value in $plone-colors-dark {
        --plone-#{$color}: #{$value};
      }
    
      @each $color,
      $value in $state-colors-dark {
        --plone-state-#{$color}: #{$value};
      }
    }
}