.plone-icon {
  height: 1em;
  width: auto;
}

.icon-inline {
  // margin-bottom: 0.25em;
  vertical-align: middle;
  bottom: -0.125em;
  position: relative;
}

.icon-x15 {
  height: 1.5em;
  width: auto;
}

.icon-x2 {
  height: 2em;
  width: auto;
}

.icon-x3 {
  height: 3em;
  width: auto;
}

.icon-x4 {
  height: 4em;
  width: auto;
}

.icon-x5 {
  height: 5em;
  width: auto;
}