// @include media-breakpoint-up(xs) {
//   body:before {
//       content: "xs";
//   }
// }
// @include media-breakpoint-up(sm) {
//   body:before {
//       content: "sm";
//   }
// }
// @include media-breakpoint-up(md) {
//   body:before {
//       content: "md";
//   }
// }
// @include media-breakpoint-up(lg) {
//   body:before {
//       content: "lg";
//   }
// }
// @include media-breakpoint-up(xl) {
//   body:before {
//       content: "xl";
//   }
// }
// @include media-breakpoint-up(xxl) {
//   body:before {
//       content: "xxl";
//   }
// }

// -- Bootstrap grid definitions --
// content-column only:
body.col-content {
  #portal-column-content {
    @include make-col-ready();
    @include make-col(12);
  }
}

// content-column + col-two:
body.col-two.col-content {
  #portal-column-content {
    @include make-col-ready();

    @include media-breakpoint-up(md) {
      @include make-col(12);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(9);
    }
  }

  #portal-column-two {
    @include make-col-ready();

    @include media-breakpoint-up(md) {
      @include make-col(12);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(3);
    }
  }
}

// content-column + col-one:
body.col-one.col-content {
  #portal-column-content {
    @include make-col-ready();

    @include media-breakpoint-up(md) {
      @include make-col(12);
    }

    @include media-breakpoint-up(lg) {
      order: 2;
      @include make-col(9);
    }
  }

  #portal-column-one {
    @include make-col-ready();

    @include media-breakpoint-up(md) {
      @include make-col(12);
    }

    @include media-breakpoint-up(lg) {
      order: 1;
      @include make-col(3);
    }
  }
}

// all 3 columns:
body.col-one.col-two.col-content {
  #portal-column-content {
    @include make-col-ready();

    @include media-breakpoint-up(sm) {
      @include make-col(12);
    }

    @include media-breakpoint-up(md) {
      @include make-col(12);
    }

    @include media-breakpoint-up(lg) {
      order: 2;
      @include make-col(6);
    }
  }

  #portal-column-one {
    @include make-col-ready();

    @include media-breakpoint-up(sm) {
      @include make-col(12);
    }

    @include media-breakpoint-up(md) {
      @include make-col(6);
    }

    @include media-breakpoint-up(lg) {
      order: 1;
      @include make-col(3);
    }
  }

  #portal-column-two {
    @include make-col-ready();

    @include media-breakpoint-up(sm) {
      @include make-col(12);
    }

    @include media-breakpoint-up(md) {
      @include make-col(6);
    }

    @include media-breakpoint-up(lg) {
      order: 3;
      @include make-col(3);
    }
  }
}