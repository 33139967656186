.card-light {
  border: none;

  .card-body {
    padding: 0;
  }
}

.card-icon {
  font-size: 2em;
  text-align: center;
  padding-top: 1.25rem;
}
