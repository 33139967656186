// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only,
.hiddenStructure,
.crud-form .header-select {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: #fff;
  background-color: #000;
}

.sr-only a,
.hiddenStructure a {
  color: #fff;
  background-color: #000;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable {

  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}