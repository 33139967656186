//*// BREADCRUMBS //*//

#portal-breadcrumbs {
  background-color: $breadcrumb-bg;
  margin-bottom: $breadcrumb-margin-bottom;

  .breadcrumb {
    background-color: transparent;
  }

}